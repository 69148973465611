import React from 'react';

import Layout from '../components/Layout';
import logo from '../assets/images/cellerMinifundi.png';
import { injectIntl } from "gatsby-plugin-intl";

const IndexPage = () => (
  <Layout>
    <article id="cookies-aviso-legal">
      <header>
        <img className="logo" src={logo} alt="Celler del Minifundi" />
        <h3>Vermut Ferrer</h3>
      </header>
      <section className="wrapper">
        <div className="inner">
          <section>
          </section>
        </div>
      </section>
    </article>
  </Layout>
);

export default injectIntl(IndexPage);
